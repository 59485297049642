<template>
	<div />
</template>

<script>
/**********************************************************************************************
**** DUMMY ***********************************************************************************
***********************************************************************************************
* 
*/

export default {
	name: 'DummyPage',
	components: {  },
	data: () => {
		return {
		}
	},
	computed: {
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
	},
	mounted() {
	},
	methods: {

	},
}
</script>

<style scoped lang="less">

</style>